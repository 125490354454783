import { Link } from "react-router-dom"

function Home () {
  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
      <main>
        <h2>欢迎来到 Home 页面</h2>
      </main>
      <Link to="/about">关于</Link>
      <Link to="/help">帮助</Link>
      <Link to="/coms">Antd</Link>
    </div >
  )
}
export default Home;
