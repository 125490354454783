import './App.css';
import { Routes, Route } from "react-router-dom"
import About from './pages/about'
import Home from './pages/home'
import Help from './pages/help'
import Coms from './pages/coms'

function App () {
  // eslint-disable-next-line no-restricted-globals
  const name = location?.host.includes("cloudfront");
  const s3 = "AWS: S3 + CloudFront + CloudFront Functions 部署React SPA项目 - 路由跳转实验"
  const oss = "阿里云: OSS + 自定义域名 部署React SPA项目 - 路由跳转实验"

  return (
    <div className="App">
      <header className="App-header">
        {name ? s3 : oss}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/coms" element={<Coms />}></Route>
        </Routes>
      </header>
    </div>
  );
}

export default App;

