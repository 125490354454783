import { Link } from "react-router-dom"

function Help () {
  return <div>
    <main>
      <h2>欢迎来到 Help 页面</h2>
    </main>
    <Link to="/about">关于</Link>
  </div >
}
export default Help;
