import { Link } from "react-router-dom"

function About () {
  return <div>
    <main>
      <h2>欢迎来到 About 页面</h2>
    </main>
    <nav>
      <Link to="/">首页</Link>
    </nav>
  </div>
}
export default About;
